import React, { useState } from "react";
import Hero from "../components/Hero";
import OngoingProjects from "../components/OngoingProjects";
import Popup from "../components/Popup";
import OurTestmonials from "../components/OurTestmonials";
import CompletedProjects from "../components/CompletedProjects";
import Newsletter from "../components/common/Newsletter";
import Footer from "../components/common/Footer";
import About from "../components/About";
import BackToTop from "../components/common/BackToTop";
import BookPopup from "../components/bookPopup";

const HomePage = () => {
  return (
    <div>
      <Popup />
      <BookPopup />
      <Hero />
      <OngoingProjects />
      <CompletedProjects />
      <About />
      {/* <OurTestmonials /> */}
      <Newsletter />
      <Footer />
      {/* <BackToTop/> */}
    </div>
  );
};

export default HomePage;
