import React from "react";
import { SubHeading } from "./custom-ui/CommonHeading";
import NavBar from "./NavBar";
import Cta from "./custom-ui/Cta";
import circle from "../assets/images/webp/discover-circle.webp";
import akatha from "../assets/images/png/akatha approved.png";
import biaapa from "../assets/images/png/biaapa.png";
import rera from "../assets/images/png/rera.png";
import { usePopup } from "../context/popupContext";
const Hero = () => {
  const { isPopupVisible, setIsPopupVisible } = usePopup();
  return (
    <div id="home" className="flex flex-col">
      <NavBar />
      <div className="px-5 ">
        <div className="bg-hero bg-left-bottom bg-no-repeat max-w-[1400px] mx-auto bg-cover custom-2xl:bg-full h-full min-h-[500px] md:min-h-[550px] lg:!min-h-[690px] pt-10 md:pt-10 lg:pb-[100px] rounded-2xl relative max-lg:pb-0 overflow-hidden xl:ps-0 sm:ps-5 ps-0">
          <div className="container max-w-[1180px] mx-auto sm:px-5 px-4">
            <SubHeading>Welcome to Visionary Venture</SubHeading>
            <h1 className="xl:text-custom-md lg:text-7xl text-[40px] font-gloock !leading-120 text-black max-w-[655px] mt-2.5 sm:mb-5 mb-3">
              Your Dream{" "}
              <span className=" text-yellow hero-bottomline after:!top-[23px]">
                Home
              </span>{" "}
              Awaits
            </h1>
            <p className="font-lato text-lg font-medium leading-normal text-light-black mb-4 sm:mb-8">
              Premium Residential plots and Villas
            </p>
            <Cta className="mb-5" onClick={() => setIsPopupVisible(true)}>
              Enquire Now
            </Cta>
            <div className="flex gap-3 items-center max-lg:pb-10">
              <p className="font-lato text-lg sm:text-xl md:text-2xl font-light leading-none">
                <span className="font-bold leading-140">500+</span> Villas plots
              </p>
              <span className="h-[30px] w-[1.16px] bg-light-black"></span>
              <p className="font-lato text-lg sm:text-xl md:text-2xl font-light leading-none">
                <span className="font-bold leading-140">5+</span> Completed
                projects
              </p>
            </div>
          </div>
          {/* <img src={circle} alt="circle"  /> */}
          <div className="xl:size-[127px] md:size-24 max-md:size-32 absolute md:top-10 md:right-0 lg:top-28 max-md:bottom-6 max-lg:right-[20%] max-custom-lg:right-[35%] lg:right-[20%]">
            <h4 className="text-black text-center text-lg font-bold">
              Approved by
            </h4>
            <div className="flex justify-center items-center gap-3">
              <img
                src={akatha}
                alt="akahta approved"
                className="w-[125px] h-[125px] object-cover"
              />
              <img
                src={biaapa}
                alt="akahta approved"
                className="w-[125px] h-[125px] object-cover"
              />
              <img
                src={rera}
                alt="akahta approved"
                className="w-[125px] h-[125px] object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
