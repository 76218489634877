import React from "react";
import PrimaryHeading, { SubHeading } from "../custom-ui/CommonHeading";
import locationIcon from "../../assets/images/elegant/svg/map-location.svg";
import ZoomInOut from "../../assets/images/elegant/svg/zoom-in-out.svg";
import { LOCATION_DATA_LIST } from "../../utils/helper";
import map from "../../assets/images/elegant/webp/location-map.webp";
import plusIcon from "../../assets/images/elegant/svg/plus-icon.svg";
import minusIcon from "../../assets/images/elegant/svg/minus-icon.svg";
import mapPegMan from "../../assets/images/elegant/svg/pegman-map.svg";

const Location = () => {
  return (
    <div className="xl:pt-[140px] lg:pt-20 sm:pt-16 pt-14">
      <div className="container xl:max-w-[1164px] mx-auto px-5">
        <div className="flex flex-wrap justify-center items-center">
          <div className="lg:w-[34%] w-full flex max-lg:justify-center items-center">
            <div className="mb-0">
              <SubHeading className="max-lg:justify-center">
                Location
              </SubHeading>
              <PrimaryHeading className="md:mt-[10px] mt-1 lg:pb-[43px] pb-4  max-lg:text-center">
                Route
                <span className="text-yellow map-bottomline"> Map</span>
              </PrimaryHeading>
              {LOCATION_DATA_LIST.map((location, index) => (
                <div
                  key={index}
                  className="flex items-center lg:gap-4 gap-3 sm:pb-4 pb-3"
                >
                  <img
                    width={24}
                    height={24}
                    className="sm:size-[24px] size-[18px] object-cover"
                    src={locationIcon}
                    alt="locationIcon"
                  />
                  <p className="font-lato font-semibold sm:text-xl text-base leading-160 text-light-black">
                    {location.name} -{" "}
                    <span className="font-normal sm:text-base text-sm leading-156">
                      {location.time}
                    </span>
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="lg:w-[65%] w-full flex flex-col justify-center lg:mt-0 sm:mt-8 mt-6">
            <div className="relative max-w-[766px]" onClick={()=>{
              window.open("https://maps.app.goo.gl/dDKiyWP3XBxg7UDu7", "_blank");
            }}>
              <div className="border border-solid border-light-yellow-15 shadow-map rounded-2xl">
                <img
                  width={766}
                  height={542}
                  className="xl:w-[766px] lg:max-w-[766px] md:max-w-[650px] max-w-[500px] sm:h-[420px] lg:h-[542px] lg:max-h-[542px]  object-cover rounded-2xl w-full"
                  src={map}
                  alt="map-image"
                />
              </div>
              <div className="absolute top-4 right-4 bg-yellow sm:size-[45px] size-[35px] rounded flex items-center justify-center">
                <img
                  width={30}
                  height={30}
                  className="sm:size-[30px] size-[20px] object-cover"
                  src={ZoomInOut}
                  alt="ZoomInOut"
                />
              </div>
              <div className="absolute bottom-4 left-4 bg-yellow sm:w-[43px] w-[38px] sm:h-[84px] h-[70px] rounded flex flex-col items-center justify-center sm:pt-3 sm:pb-5 py-3">
                <img
                  width={18}
                  height={18}
                  className="sm:size-[18px] size-[13px] object-cover"
                  src={plusIcon}
                  alt="plusIcon"
                />
                <div className="border border-solid border-light-black opacity-40 w-full mt-3 sm:mb-5 mb-4"></div>
                <img
                  width={23}
                  height={3}
                  className="sm:w-[23px] sm:h-[3px] w-[19px] h-[2px] object-cover"
                  src={minusIcon}
                  alt="minusIcon"
                />
              </div>
              <div className="absolute right-[23px] bottom-[10px]">
                <img
                  width={45}
                  height={45}
                  className="sm:size-[45px] size-[35px] rounded object-cover"
                  src={mapPegMan}
                  alt="mapPegMan"
                />
              </div>
              <div className="absolute bottom-[10px] h-[36px] lg:right-[138px] sm:right-[100px] right-[75px] w-full flex items-center justify-center bg-yellow sm:max-w-[165px] max-w-[144px] rounded">
                <div className="flex items-center gap-2">
                  <svg
                    width="7"
                    height="13"
                    viewBox="0 0 7 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.91016 1L0.910156 6.86667L5.91016 12"
                      stroke="#010101"
                    />
                  </svg>
                  <p className="font-gloock font-normal sm:font-base text-sm leading-120 text-light-black">
                    Prev
                  </p>
                </div>
                <div className="border border-solid border-light-black opacity-20 h-full sm:mx-[19px] mx-[13px]"></div>
                <div className="flex items-center gap-2">
                  <p className="font-gloock font-normal sm:font-base text-sm leading-120 text-light-black">
                    Next
                  </p>
                  <svg
                    width="7"
                    height="13"
                    viewBox="0 0 7 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.910156 1L5.91016 6.86667L0.910156 12"
                      stroke="#010101"
                    />
                  </svg>
                </div>
              </div>
             
            </div>
            <a
                  href="https://maps.app.goo.gl/dDKiyWP3XBxg7UDu7"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link"
                >
                <button className="rounded-full px-6 py-3 bg-yellow text-black hover:bg-black hover:text-yellow transition-all ease-linear duration-300 font-semibold text-lg capitalize w-full mt-4">
                  Click here for google map
                </button>
                </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Location;
