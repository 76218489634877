import React, { useState, useRef, useEffect } from "react";
import ongoingProjects from "../assets/images/webp/ongoing-projects.webp";
import {
  ACCORDIONDATA,
  ACCORDIONIMAGE,
} from "../utils/helper";
import PrimaryHeading, { SubHeading } from "./custom-ui/CommonHeading";
import Icons from "./common/Icons";
import playIcon from "../assets/images/svg/video-play-icon.svg";
import ongoingProjects4 from "../assets/images/webp/ongoing-project-4.webp";
import ongoingProjects2 from "../assets/images/png/image_about.png";
import securityChecked from "../assets/images/svg/security-checked-icon.svg";
import electricConnection from "../assets/images/svg/electric-connection-icon.svg";
import individualWater from "../assets/images/svg/individual-water-icon.svg";
import thumb1 from "../assets/images/png/thumb1.png";
import thumb2 from "../assets/images/png/thumb2.png";
import thumb3 from "../assets/images/png/thumb3.png";
import thumb4 from "../assets/images/png/thumb4.png";
import thumb5 from "../assets/images/png/thumb5.png";
import thumb6 from "../assets/images/png/thumb6.png";
import childrensPlay from "../assets/images/svg/childerns-play-icon.svg";
import villa from "../assets/images/svg/villa-svgrepo-com.svg";
import doorOpen from "../assets/images/svg/door-open-svgrepo-com.svg";
import realEstate from "../assets/images/svg/reshot-icon-real-estate-ZUXC5S3JKH.svg";
import park from "../assets/images/svg/reshot-icon-park-nature-FZEXA853NP.svg";
import wasteSewer from "../assets/images/svg/waste-sewer-svgrepo-com.svg";
import earth from "../assets/images/svg/planet-earth-svgrepo-com.svg";
import streetLight from "../assets/images/svg/street-light-street-svgrepo-com.svg";
import property from "../assets/images/svg/building-house-property-office-city-svgrepo-com.svg";
import brochure from "../assets/download/Elegent Palms Brochure.pdf";
import demoVideo from '../assets/videos/demo-two.mp4'
import { IoMdCloseCircle } from "react-icons/io";

const OngoingProjects = () => {
  const [openAccordion, setOpenAccordion] = useState(0);
  const [contentHeight, setContentHeight] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);
  const [isVideoPopupOpen, setIsVideoPopupOpen] = useState(false);
  
  const videoRef = useRef(null);
  const playButtonRef = useRef(null);
  
  const refs = useRef([]);

  const videoData = [
    { thumbnail: thumb1, videoUrl: "https://huudrnga8d08ey5d.public.blob.vercel-storage.com/VEP0-3Rf8zdFKRVM4k15Z34L9yLxgymt1hu.MOV" },
    { thumbnail: thumb2, videoUrl: "https://huudrnga8d08ey5d.public.blob.vercel-storage.com/VEP3-vNbgGPwjZxvRFOkcW1gBJoLvChTg0D.MOV" },
    { thumbnail: thumb3, videoUrl: "https://huudrnga8d08ey5d.public.blob.vercel-storage.com/VEP4-vh8daYjdMOlSgIfGcPH3CIkkR5CU88.MOV" },
    { thumbnail: thumb4, videoUrl: "https://huudrnga8d08ey5d.public.blob.vercel-storage.com/VEP5-CMi0DM1DdvJUXg645zqW3JoX2R2y9a.MOV" },
    { thumbnail: thumb5, videoUrl: "https://huudrnga8d08ey5d.public.blob.vercel-storage.com/VEP6-sFTia7Ex95DGuCR3102zrh4hNzi6tV.MOV" },
    { thumbnail: thumb6, videoUrl: "https://huudrnga8d08ey5d.public.blob.vercel-storage.com/VEP7-i8TzH6bXmQfplwgkiWVXCf2AXEGCfG.MOV" },
  ];

  const [currentVideoUrl, setCurrentVideoUrl] = useState("");

  useEffect(() => {
    const heights = {};
    refs.current.forEach((ref, index) => {
      if (ref) {
        heights[index] = ref.scrollHeight;
      }
    });
    setContentHeight(heights);
  }, [refs, openAccordion]);

  const toggleAccordion = (index) => {
    setOpenAccordion((prevState) => (prevState === index ? null : index));
  };

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handlePause = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
    setIsPlaying(false);
  };

  const handleVideoPopup = (url) => {
    setCurrentVideoUrl(url);
    setIsVideoPopupOpen(true);
  };

  const closeVideoPopup = () => {
    setIsVideoPopupOpen(false);
  };

  return (
    <div id="project" className="xl:pt-[170px] lg:pt-20 sm:pt-16 pt-14">
      <div className="container xl:max-w-[1164px] mx-auto md:px-3 px-5">
        <div className="flex lg:flex-row flex-col-reverse flex-wrap lg:justify-between justify-center">
          <div className="xl:w-[47%] lg:w-6/12 w-full md:px-3 xl:px-0 flex flex-col justify-center max-xl:items-center lg:pt-0 md:pt-12 pt-10 gap-10">
            <div className="h-full flex max-xl:items-center">
              {
                openAccordion === 0 ? (
                  <div className="grid grid-cols-2 gap-10 h-fit ">
                
                      <div className="bg-white flex flex-col justify-center items-center sm:py-5 p-3 xl:h-[144px] sm:px-[30px] rounded-[6px] drop-shadow-light-black hover:drop-shadow-black transition-all ease-linear duration-300 max-w-[222px] w-full">
                        <img
                          src={villa}
                          alt="amenities-card-icon"
                          className="size-[38px]"
                        />
                        <p className="font-semibold sm:text-base text-sm text-light-black font-lato leading-160 mt-[14px] text-center">
                          Personalised Villas
                        </p>
                      </div>
                      <div className="bg-white flex flex-col justify-center items-center sm:py-5 p-3 xl:h-[144px] sm:px-[30px] rounded-[6px] drop-shadow-light-black hover:drop-shadow-black transition-all ease-linear duration-300 max-w-[222px] w-full">
                        <img
                          src={realEstate}
                          alt="amenities-card-icon"
                          className="size-[35px]"
                        />
                        <p className="font-semibold sm:text-base text-sm text-light-black font-lato leading-160 mt-[14px] text-center">
                          52 Villa Plots
                        </p>
                      </div>
        
                      <div className="bg-white flex flex-col justify-center items-center sm:py-5 p-3 xl:h-[144px] sm:px-[30px] rounded-[6px] drop-shadow-light-black hover:drop-shadow-black transition-all ease-linear duration-300 max-w-[222px] w-full">
                        <img
                          src={earth}
                          alt="amenities-card-icon"
                          className="size-[35px]"
                        />
                        <p className="font-semibold sm:text-base text-sm text-light-black font-lato leading-160 mt-[14px] text-center">
                          3.5 Acres of Land
                        </p>
                      </div>
                      <div className="bg-white flex flex-col justify-center items-center sm:py-5 p-3 xl:h-[144px] sm:px-[30px] rounded-[6px] drop-shadow-light-black hover:drop-shadow-black transition-all ease-linear duration-300 max-w-[222px] w-full">
                        <img
                          src={property}
                          alt="amenities-card-icon"
                          className="size-[35px]"
                        />
                        <p className="font-semibold sm:text-base text-sm text-light-black font-lato leading-160 mt-[14px] text-center">
                          Premium Residential Plots
                        </p>
                 
                    </div>
                  </div>
                )
              :
              openAccordion === 1 ? (
                <div className="grid grid-cols-2 gap-10 h-full">
                
                    <div className="bg-white flex flex-col justify-center items-center sm:py-5 p-3 xl:h-[144px] sm:px-[30px] rounded-[6px] drop-shadow-light-black hover:drop-shadow-black transition-all ease-linear duration-300 max-w-[222px] w-full">
                      <img
                        src={securityChecked}
                        alt="amenities-card-icon"
                        className="size-[38px]"
                      />
                      <p className="font-semibold sm:text-base text-sm text-light-black font-lato leading-160 mt-[14px] text-center">
                        Gated Community with 24 hrs Security
                      </p>
                    </div>
                    <div className="bg-white flex flex-col justify-center items-center sm:py-5 p-3 xl:h-[144px] sm:px-[30px] rounded-[6px] drop-shadow-light-black hover:drop-shadow-black transition-all ease-linear duration-300 max-w-[222px] w-full">
                      <img
                        src={electricConnection}
                        alt="amenities-card-icon"
                        className="size-[38px]"
                      />
                      <p className="font-semibold sm:text-base text-sm text-light-black font-lato leading-160 mt-[14px] text-center">
                        Underground Wiring & Electric Connection
                      </p>
                    </div>
                    <div className="bg-white flex flex-col justify-center items-center sm:py-5 p-3 xl:h-[144px] sm:px-[30px] rounded-[6px] drop-shadow-light-black hover:drop-shadow-black transition-all ease-linear duration-300 max-w-[222px] w-full">
                    <img
                        src={doorOpen}
                        alt="street-card-icon"
                        className="size-[38px]"
                      />
                      <p className="font-semibold sm:text-base text-sm text-light-black font-lato leading-160 mt-[14px] text-center">
                        Arched Entrance
                      </p>
                    </div>
                    <div className="bg-white flex flex-col justify-center items-center sm:py-5 p-3 xl:h-[144px] sm:px-[30px] rounded-[6px] drop-shadow-light-black hover:drop-shadow-black transition-all ease-linear duration-300 max-w-[222px] w-full">
                    <img
                        src={streetLight}
                        alt="street-card-icon"
                        className="size-[38px]"
                      />
                      <p className="font-semibold sm:text-base text-sm text-light-black font-lato leading-160 mt-[14px] text-center">
                        LED Street Lights
                      </p>
                    </div>
                    <div className="bg-white flex flex-col justify-center items-center sm:py-5 p-3 xl:h-[144px] sm:px-[30px] rounded-[6px] drop-shadow-light-black hover:drop-shadow-black transition-all ease-linear duration-300 max-w-[222px] w-full">
                    <img
                        src={wasteSewer}
                        alt="waster-card-icon"
                        className="size-[38px]"
                      />
                      <p className="font-semibold sm:text-base text-sm text-light-black font-lato leading-160 mt-[14px] text-center">
                        S.T.P
                      </p>
                    </div>
             
                
                    <div className="bg-white flex flex-col justify-center items-center sm:py-5 p-3 xl:h-[144px] sm:px-[30px] rounded-[6px] drop-shadow-light-black hover:drop-shadow-black transition-all ease-linear duration-300 max-w-[222px] w-full">
                      <img
                        src={individualWater}
                        alt="amenities-card-icon"
                        className="size-[38px]"
                      />
                      <p className="font-semibold sm:text-base text-sm text-light-black font-lato leading-160 mt-[14px] text-center">
                        Individual Water and Drainage Connections
                      </p>
                    </div>
                    <div className="bg-white flex flex-col justify-center items-center sm:py-5 p-3 xl:h-[144px] sm:px-[30px] rounded-[6px] drop-shadow-light-black hover:drop-shadow-black transition-all ease-linear duration-300 max-w-[222px] w-full">
                      <img
                        src={childrensPlay}
                        alt="amenities-card-icon"
                        className="size-[38px]"
                      />
                      <p className="font-semibold sm:text-base text-sm text-light-black font-lato leading-160 mt-[14px] text-center">
                        Childrens Play Area
                      </p>
                    </div>
                    <div className="bg-white flex flex-col justify-center items-center sm:py-5 p-3 xl:h-[144px] sm:px-[30px] rounded-[6px] drop-shadow-light-black hover:drop-shadow-black transition-all ease-linear duration-300 max-w-[222px] w-full">
                    <img
                        src={park}
                        alt="street-card-icon"
                        className="size-[38px]"
                      />
                      <p className="font-semibold sm:text-base text-sm text-light-black font-lato leading-160 mt-[14px] text-center">
                        Park
                      </p>
                    </div>
                
                </div>
              ) : openAccordion === 3 ? (
                <div className="relative lg:mb-10 md:mb-8 mb-6 group max-w-[501px] grid grid-cols-2 gap-10">
              {videoData.map((item, index) => (
                <div className="relative">
                <img
                  src={item.thumbnail}
                  alt={`video-thumbnail-${index}`}
                  className="w-full h-full object-cover cursor-pointer rounded-2xl "
                  onClick={() => handleVideoPopup(item.videoUrl)}
                />
             
                  <img
                    src={playIcon}
                    alt="playIcon"
                    className="rounded-full w-[60px] h-[60px] absolute inset-0 m-auto cursor-pointer group-hover:scale-105 transition-all ease-linear duration-300"
                    onClick={() => handleVideoPopup(item.videoUrl)}
                  />
                </div>
              ))}

                </div>
              ) : openAccordion === 4 ? (
                <div className="relative lg:mb-10 md:mb-8 mb-6 group max-w-[501px]">
                  <img
                    src={ongoingProjects2}
                    alt="Video Thumbnail"
                    className="rounded-2xl max-sm:w-full max-sm:max-w-[501px] sm:min-w-[501px] lg:min-w-[400px] max-sm:h-[303px] xl:min-w-[501px] object-cover sm:h-[397px] cursor-pointer"
                    onClick={() => handleVideoPopup("https://huudrnga8d08ey5d.public.blob.vercel-storage.com/VEP%20Shoot%20with%20interview%20edited-Wkgwsyah8SNFp4TMG1KHjQZDl0DJKO.mp4")}
                  />
                  <img
                    src={playIcon}
                    alt="playIcon"
                    className="rounded-full w-[60px] h-[60px] absolute inset-0 m-auto cursor-pointer group-hover:scale-105 transition-all ease-linear duration-300"
                    onClick={() => handleVideoPopup("https://huudrnga8d08ey5d.public.blob.vercel-storage.com/VEP%20Shoot%20with%20interview%20edited-Wkgwsyah8SNFp4TMG1KHjQZDl0DJKO.mp4")}
                    />
                  </div> 
              ) : (
                <div className="flex flex-col gap-4">
                <a
                  href="https://maps.app.goo.gl/dDKiyWP3XBxg7UDu7"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link"
                >
                  <img
                    src={ACCORDIONIMAGE[openAccordion] || ongoingProjects}
                    alt="ongoingProjects"
                    className="rounded-2xl w-full max-w-[501px] max-h-[454px] drop-shadow-black h-full"
                  />
                </a>
                <a
                  href="https://maps.app.goo.gl/dDKiyWP3XBxg7UDu7"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link"
                >
                <button className="rounded-full px-6 py-3 bg-yellow text-black hover:bg-black hover:text-yellow transition-all ease-linear duration-300 font-semibold text-lg capitalize w-full">
                  Click here for google map
                </button>
                </a>

                
                </div>
              )}
            </div>
          </div>
          <div className="xl:w-[52%] lg:w-6/12 w-full md:px-3 xl:px-0 flex flex-col lg:items-end items-center">
            <div className="w-full max-lg:justify-center max-lg:items-center mx-auto flex flex-col">
              <SubHeading className="max-lg:justify-center">
                Ongoing Projects
              </SubHeading>
              <PrimaryHeading className="md:mt-[10px] mt-1 max-lg:text-center">
                Visionary Elegant&nbsp;
                <span className="text-yellow xl:elegant-palm-bottomline">
                  Palms
                </span>
              </PrimaryHeading>
              <div className="flex flex-col max-w-[566px] gap-4 lg:mt-10 md:mt-8 mt-7 w-full">
                {ACCORDIONDATA.map((obj, index) => (
                  <div
                    onClick={() => {
                      if (index === 5) {
                        window.location.href = brochure;
                      } else {
                        toggleAccordion(index);
                      }
                    }}
                    className={`${
                      openAccordion === index
                        ? "bg-yellow"
                        : "bg-light-yellow-300 hover:shadow-faq-item transition-all ease-linear duration-300"
                    } accordion-item sm:pl-5 sm:pr-7 px-[10px] sm:py-[14px] py-3 rounded-[6px] transition-all duration-300 ease-in-out cursor-pointer`}
                    key={index}
                  >
                    <div className="flex items-start gap-4">
                      <img
                        src={obj.icon}
                        alt="faq-icons"
                        className="md:size-9 size-7"
                      />
                      <div className="w-full">
                        <div className="accordion-title flex justify-between sm:items-center items-start cursor-pointer max-sm:gap-[0.2px]">
                          <div className="flex items-center justify-between w-full">
                            <p className="font-normal font-gloock text-light-black leading-130 xl:text-2xl md:text-xl text-lg">
                              {obj.title}
                            </p>
                            <div className="sm:pt-0 pt-2">
                              <div
                                className={`${
                                  openAccordion === index ? "rotate-180" : ""
                                } transition-transform duration-300 ease-linear`}
                              >
                                <Icons icon="faq-arrow" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`accordion-content overflow-hidden transition-all duration-500 ease-in-out`}
                          style={{
                            height:
                              openAccordion === index
                                ? `${contentHeight[index]}px`
                                : "0px",
                            opacity: openAccordion === index ? 1 : 0,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                ))}
     
                {openAccordion === 5 && (
                  <a href={brochure} download className="link">
                    <button className="rounded-full px-6 py-3 bg-yellow text-black font-semibold text-lg">Download Brochure</button>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isVideoPopupOpen && (
        <div className="video-popup-overlay">
          <div className="video-popup-content border-white max-w-[1200px] max-h-[800px]">
            <button className="close-button" onClick={closeVideoPopup}><IoMdCloseCircle className="h-8 w-8 hover:rotate-90 transition-all ease-linear duration-300" /></button>
            <video
              src={currentVideoUrl}
              controls
              autoPlay
              className="rounded-2xl w-full h-full"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default OngoingProjects;
